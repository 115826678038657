<template>
	<section class="main">
		<div>
			<h1>Page not found</h1>
			Click <nuxt-link to="/"> here </nuxt-link> to return
		</div>
	</section>
</template>

<script>
export default {
	name: 'IndexPage',
};
</script>

<style scoped>
.main {
	min-height: 75vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
